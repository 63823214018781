@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Inter,Helvetica Neue,Arial,Hiragino Kaku Gothic ProN,Hiragino Sans,Meiryo,sans-serif;
  word-break: break-all;
  color: #fff;
  background: #111;
}

.max-w-700px {
  max-width: 700px;
}

.container {
  max-width: 700px;
  margin: 0 auto;
}

@media (max-width: 1023px) {
  .container {
    padding: 36px;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 24px 16px;
  }
}

/* TODO:Tailwind側で吸収 */
.text-222 {
  color: #222;
}

.text-999 {
  color: #999;
}

.bg-111 {
  background: #111;
}

.bg-222 {
  background: #222;
}

.border-999 {
  border: solid 2px #999;
}

.border-t-999 {
  border-top: solid 2px #999;
}

.border-b-999 {
  border-bottom: solid 2px #999;
}

/* twemoji */
.emoji {
  height: 80px;
}